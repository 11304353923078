import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProperty, resetProperty } from '@a1-ict/fsbo/propertySlice';
import { selectProperty } from '@a1-ict/fsbo/propertySlice/selectors';

import Layout from '../components/layouts/Layout';

import PropertyPreview from '../components/Properties/PropertyPreview/PropertyPreview';
import SEO from '../components/Seo';

const Property = ({ pageContext }) => {
  const { property } = pageContext;
  const dispatch = useDispatch();
  const dynamicProperty = useSelector(selectProperty);

  useEffect(() => {
    if (property.id) {
      dispatch(fetchProperty({ id: property.id }));
    }

    dispatch(resetProperty(false));

    return () => {
      dispatch(resetProperty(true));
    };
  }, []);

  return (
    <Layout navColor="white">
      {/* <SEO
        title={property.title}
        description={property.description}
        image={
          property.images && property.images.length > 0 && property.images[0].localFile.publicURL
        }
      /> */}

      <PropertyPreview
        className="margined-by-layout"
        property={{
          ...property,
          price: property.price || property.square_price,
          is_favorite: dynamicProperty.is_favorite
            ? dynamicProperty.is_favorite
            : property.is_favorite,
        }}
        isBuilding={property.is_building}
      ></PropertyPreview>
    </Layout>
  );
};

export default Property;
